import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Account Pages
const Warehouses = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/Warehouses.vue');
const AddWarehouse = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/AddWarehouse.vue');
const EditWarehouse = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/EditWarehouse.vue');
const Clients = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/Clients.vue');
const AddClient = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/AddClient.vue');
const EditClient = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/EditClient.vue');
const AddProduct = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/AddProduct.vue');
const EditProduct = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/EditProduct.vue');
const StorageLocations = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/StorageLocations.vue');
const AddStorageLocation = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/AddStorageLocation.vue');
const EditStorageLocation = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/EditStorageLocation.vue');
const LaborActions = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/LaborActions.vue');
const Products = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/WarehouseAccount/Products.vue');
const JobsMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/WarehouseAccount/JobsMap.vue')
const Product  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/WarehouseAccount/Product.vue')

// Client Account Pages
const ClientAddInboundShipment = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/ClientAccount/AddInboundShipment.vue');
const ClientInboundShipments = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/ClientAccount/InboundShipments.vue');

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/login',
    children: [
      {
        path: '/warehouses',
        name: 'Warehouses',
        component: Warehouses,
        meta: { authOnly: true }
      },
      {
        path: '/warehouses/add',
        name: 'Add Warehouse',
        component: AddWarehouse,
        meta: { authOnly: true }
      },
      {
        path: '/warehouses/edit',
        name: 'Edit Warehouse',
        component: EditWarehouse,
        meta: { authOnly: true }
      },
      {
        path: '/clients',
        name: 'Clients',
        component: Clients,
        meta: { authOnly: true }
      },
      {
        path: '/clients/add',
        name: 'Add Client',
        component: AddClient,
        meta: { authOnly: true }
      },
      {
        path: '/clients/edit',
        name: 'Edit Client',
        component: EditClient,
        meta: { authOnly: true }
      },
      {
        path: '/products/add',
        name: 'Add Product',
        component: AddProduct,
        meta: { authOnly: true }
      },
      {
        path: '/products/edit',
        name: 'Edit Product',
        component: EditProduct,
        meta: { authOnly: true }
      },
      {
        path: '/storage-locations',
        name: 'Storage Locations',
        component: StorageLocations,
        meta: { authOnly: true }
      },
      {
        path: '/storage-locations/add',
        name: 'Add Storage Location',
        component: AddStorageLocation,
        meta: { authOnly: true }
      },
      {
        path: '/storage-locations/edit',
        name: 'Edit Storage Location',
        component: EditStorageLocation,
        meta: { authOnly: true }
      },
      {
        path: '/client/inbound-shipments',
        name: 'Client Inbound Shipments',
        component: ClientInboundShipments,
        meta: { authOnly: true }
      },
      {
        path: '/client/inbound-shipments/add',
        name: 'Client Add Inbound Shipment',
        component: ClientAddInboundShipment,
        meta: { authOnly: true }
      },
      {
        path: '/labor-actions',
        name: 'Labor Actions',
        component: LaborActions
      },
      {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: { authOnly: true }
      },
      {
        path: '/product',
        name: 'Product',
        component: Product
      },
      {
        path: '/jobs-map',
        name: 'Jobs Map',
        component: JobsMap
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
